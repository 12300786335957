<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6" md="6">
        <b-card>
          <div slot="header">
            <i class="fas fa-gavel"></i> General moderation settings
          </div>
          <div v-if="loading">
            <div class="sk-circle-fade float-left">
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
            </div>
            <h4 class="float-left ml-4">Loading settings...</h4>
          </div>
          <div v-else>
            <h4 class="subtitle">Moderation settings</h4>
            <b-form-group
              label="Modlogs channel"
              label-for="modlog_channel_select"
            >
              <multiselect
                v-model="modlog_channel"
                id="modlog_channel_select"
                track-by="id"
                label="name"
                placeholder="Please select a channel"
                :clear-on-select="false"
                :close-on-select="true"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="false"
                :options="groupedChannels"
                :allow-empty="false"
                group-values="channels"
                group-label="category"
                :group-select="false"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <font-awesome-icon style="color:#72767d" icon="hashtag" />
                    <span class="option__title ml-2">{{
                      props.option.name
                    }}</span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">
                      <font-awesome-icon
                        style="color:#72767d"
                        :icon="
                          !props.option['$isLabel'] ? 'hashtag' : 'chevron-down'
                        "
                      />
                      {{ props.option.name || props.option.$groupLabel.name }}
                    </span>
                  </div>
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group
              label="Send reports to"
              label-for="reports_channel_select"
            >
              <multiselect
                v-model="report_channel"
                id="reports_channel_select"
                track-by="id"
                label="name"
                placeholder="Please select a channel"
                :clear-on-select="false"
                :close-on-select="true"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="false"
                :options="groupedChannels"
                :allow-empty="false"
                group-values="channels"
                group-label="category"
                :group-select="false"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <font-awesome-icon style="color:#72767d" icon="hashtag" />
                    <span class="option__title ml-2">{{
                      props.option.name
                    }}</span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">
                      <font-awesome-icon
                        style="color:#72767d"
                        :icon="
                          !props.option['$isLabel'] ? 'hashtag' : 'chevron-down'
                        "
                      />
                      {{ props.option.name || props.option.$groupLabel.name }}
                    </span>
                  </div>
                </template>
              </multiselect>
            </b-form-group>
            <b-list-group>
              <h4 class="smalltitle">Logged events</h4>
              <p>Requires a modlog to work</p>
              <b-list-group-item for="checkbox4">
                <label for="checkbox4" style="margin:0">
                  <b-form-checkbox id="checkbox4" v-model="statuses[0]"
                    >Bans</b-form-checkbox
                  >
                </label>
              </b-list-group-item>

              <b-list-group-item for="checkbox5">
                <label for="checkbox5" style="margin:0">
                  <b-form-checkbox id="checkbox5" v-model="statuses[1]"
                    >Mutes</b-form-checkbox
                  >
                </label>
              </b-list-group-item>

              <b-list-group-item for="checkbox6">
                <label for="checkbox6" style="margin:0">
                  <b-form-checkbox id="checkbox6" v-model="statuses[2]"
                    >Warnings</b-form-checkbox
                  >
                </label>
              </b-list-group-item>

              <b-list-group-item for="checkbox7">
                <label for="checkbox7" style="margin:0">
                  <b-form-checkbox id="checkbox7" v-model="statuses[3]"
                    >Tempbans</b-form-checkbox
                  >
                </label>
              </b-list-group-item>

              <b-list-group-item for="checkbox8">
                <label for="checkbox8" style="margin:0">
                  <b-form-checkbox id="checkbox8" v-model="statuses[4]"
                    >Hardmutes</b-form-checkbox
                  >
                </label>
              </b-list-group-item>

              <b-list-group-item for="checkbox9">
                <label for="checkbox9" style="margin:0">
                  <b-form-checkbox id="checkbox9" v-model="statuses[5]"
                    >Kicks</b-form-checkbox
                  >
                </label>
              </b-list-group-item>

              <b-list-group-item for="checkbox10">
                <label for="checkbox10" style="margin:0">
                  <b-form-checkbox id="checkbox10" v-model="statuses[6]"
                    >Unmutes</b-form-checkbox
                  >
                </label>
              </b-list-group-item>

              <b-list-group-item for="checkbox11">
                <label for="checkbox11" style="margin:0">
                  <b-form-checkbox id="checkbox11" v-model="statuses[7]"
                    >Unbans</b-form-checkbox
                  >
                </label>
              </b-list-group-item>
              <b-list-group-item for="checkbox12">
                <label for="checkbox12" style="margin:0">
                  <b-form-checkbox id="checkbox12" v-model="statuses[8]"
                    >Timeout</b-form-checkbox
                  >
                </label>
              </b-list-group-item>
              <b-list-group-item for="checkbox13">
                <label for="checkbox13" style="margin:0">
                  <b-form-checkbox id="checkbox13" v-model="statuses[9]"
                    >Remove Timeout</b-form-checkbox
                  >
                </label>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div slot="footer">
            <b-button variant="primary" v-on:click="put_settings('basic')"
              >Save</b-button
            >
          </div>
        </b-card>
        <b-card v-if="!loading">
          <div slot="header">
            <i class="fas fa-comment-slash"></i> Timeout settings
          </div>
          <h4 class="smalltitle">Timeout settings</h4>
          <b-form-select
            id="timeout_select"
            v-model="timeout_settings.mode"
            value="Please select"
          >
            <option
              v-for="t in creation_modes"
              :key="t.value"
              :value="t.value"
              :disabled="!is_vip && t.value === 4"
              >{{ t.name }}</option
            >
          </b-form-select>
          <b>Example:</b>
          {{ timeout_val.example.replace("muted", "timeout") }}
          <div v-if="timeout_settings.mode === 4">
            <h6 class="mt-2">Custom message</h6>
            <p>
              Supports
              <a
                href="https://docs.carl.gg/#/tagstriggers?id=advanced-usage"
                >regular tagscript.</a
              >
              <br />
              <code>{reason}</code> - The reason supplied if there was one,
              'N/A' otherwise.
              <br />
              <code>{moderator}</code> - A userblock for the responsible
              moderator
              <br />
              <code>{offender}</code> - A userblock for the offender
              <br />
              <code>{duration}</code> - A relative timestamp like '3 hours 24
              minutes' or an empty string if permanent
            </p>
            <b-input-group>
              <b-form-textarea
                :maxlength="2000"
                :rows="6"
                type="text"
                placeholder="Enter a timeout message"
                class="col-12"
                v-model="timeout_settings.message"
              ></b-form-textarea>
            </b-input-group>
            <EmbedBuilder ref="timeout_embed"></EmbedBuilder>
          </div>
          <hr />
          <h6>Default timeout duration.</h6>
          <b-form-group>
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="2"
                autocomplete="2"
                v-model="timeout_settings.hours"
                min="0"
                max="672"
                v-on:keydown="handleNonDecimalInput"
                maxlength="2"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1 mb-2">Hours</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="2"
                autocomplete="2"
                v-model="timeout_settings.minutes"
                min="0"
                max="40320"
                v-on:keydown="handleNonDecimalInput"
                maxlength="2"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Minutes</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <div slot="footer">
            <b-button variant="primary" v-on:click="put_settings('timeout')"
              >Save</b-button
            >
          </div>
        </b-card>
        <b-card v-if="!loading">
          <div slot="header">
            <i class="fas fa-comment-slash"></i> Mute settings
          </div>
          <h4 class="smalltitle">Mute settings</h4>
          <b-form-select
            id="mute_select"
            v-model="mute_settings.mode"
            value="Please select"
          >
            <option
              v-for="t in creation_modes"
              :key="t.value"
              :value="t.value"
              :disabled="!is_vip && t.value === 4"
              >{{ t.name }}</option
            >
          </b-form-select>
          <b>Example:</b>
          {{ mute_val.example }}
          <div v-if="mute_settings.mode === 4">
            <h6 class="mt-2">Custom message</h6>
            <p>
              Supports
              <a
                href="https://docs.carl.gg/#/tagstriggers?id=advanced-usage"
                >regular tagscript.</a
              >
              <br />
              <code>{reason}</code> - The reason supplied if there was one,
              'N/A' otherwise.
              <br />
              <code>{moderator}</code> - A userblock for the responsible
              moderator
              <br />
              <code>{offender}</code> - A userblock for the offender
              <br />
              <code>{duration}</code> - A relative timestamp like '3 hours 24
              minutes' or an empty string if permanent
            </p>
            <b-input-group>
              <b-form-textarea
                :maxlength="2000"
                :rows="6"
                type="text"
                placeholder="Enter a mute message"
                class="col-12"
                v-model="mute_settings.message"
              ></b-form-textarea>
            </b-input-group>
            <EmbedBuilder ref="mute_embed"></EmbedBuilder>
          </div>
          <hr />
          <h6>Default mute duration. 0 for permanent</h6>
          <b-form-group>
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="2"
                autocomplete="2"
                v-model="mute_settings.hours"
                min="0"
                max="1440"
                v-on:keydown="handleNonDecimalInput"
                maxlength="2"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1 mb-2">Hours</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="2"
                autocomplete="2"
                v-model="mute_settings.minutes"
                min="0"
                max="1440"
                v-on:keydown="handleNonDecimalInput"
                maxlength="2"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Minutes</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <div slot="footer">
            <b-button variant="primary" v-on:click="put_settings('mute')"
              >Save</b-button
            >
          </div>
        </b-card>
        <b-card v-if="!loading">
          <div slot="header">
            <i class="fas fa-user-minus"></i> Kick settings
          </div>
          <h4 class="smalltitle">Kick settings</h4>

          <b-form-select
            id="kick_select"
            v-model="kick_settings.mode"
            value="Please select"
            class="mb-1"
          >
            <option
              v-for="t in creation_modes"
              :key="t.value"
              :value="t.value"
              :disabled="!is_vip && t.value === 4"
              >{{ t.name }}</option
            >
          </b-form-select>
          <b>Example:</b>
          {{
            kick_val.example
              .replace("in", "from")
              .replace("muted", "kicked")
              .replace(" for 20 minutes", "")
          }}
          <div v-if="kick_settings.mode === 4">
            <h6 class="mt-2">Custom message</h6>
            <p>
              Supports
              <a
                href="https://docs.carl.gg/#/tagstriggers?id=advanced-usage"
                >regular tagscript.</a
              >
              <br />
              <code>{reason}</code> - The reason supplied if there was one,
              'N/A' otherwise.
              <br />
              <code>{moderator}</code> - A userblock for the responsible
              moderator
              <br />
              <code>{offender}</code> - A userblock for the offender
              <br />
            </p>
            <b-input-group>
              <b-form-textarea
                :maxlength="2000"
                :rows="6"
                type="text"
                placeholder="Enter a kick message"
                class="col-12"
                v-model="kick_settings.message"
              ></b-form-textarea>
            </b-input-group>
            <EmbedBuilder ref="kick_embed"></EmbedBuilder>
          </div>
          <div slot="footer">
            <b-button variant="primary" v-on:click="put_settings('kick')"
              >Save</b-button
            >
          </div>
        </b-card>
      </b-col>
      <b-col sm="6" md="6">
        <b-card v-if="!loading">
          <div slot="header"><i class="fas fa-gavel"></i> Ban settings</div>
          <h4 class="smalltitle">Ban settings</h4>

          <b-form-select
            id="server_channel_select"
            v-model="ban_settings.mode"
            value="Please select"
            class="mb-1"
          >
            <option
              v-for="t in creation_modes"
              :key="t.value"
              :value="t.value"
              :disabled="!is_vip && t.value === 4"
              >{{ t.name }}</option
            >
          </b-form-select>
          <b>Example:</b>
          {{
            ban_val.example
              .replace("in", "from")
              .replace(" for 20 minutes", "")
              .replace("muted", "banned")
          }}
          <div v-if="ban_settings.mode === 4">
            <h6 class="mt-2">Custom message</h6>
            <p>
              Supports
              <a
                href="https://docs.carl.gg/#/tagstriggers?id=advanced-usage"
                >regular tagscript.</a
              >
              <br />
              <code>{reason}</code> - The reason supplied if there was one,
              'N/A' otherwise.
              <br />
              <code>{moderator}</code> - A userblock for the responsible
              moderator
              <br />
              <code>{offender}</code> - A userblock for the offender
            </p>
            <b-input-group>
              <b-form-textarea
                :maxlength="2000"
                :rows="6"
                type="text"
                placeholder="Enter a ban message"
                class="col-12"
                v-model="ban_settings.message"
              ></b-form-textarea>
            </b-input-group>
            <EmbedBuilder ref="ban_embed"></EmbedBuilder>
          </div>
          <hr />
          <h6>Default ban message purge days</h6>
          <b-form-group>
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="2"
                autocomplete="2"
                min="0"
                max="1000"
                v-model="ban_settings.days"
                v-on:keydown="handleKeyDown"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Days</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <div slot="footer">
            <b-button variant="primary" v-on:click="put_settings('ban')"
              >Save</b-button
            >
          </div>
        </b-card>
        <b-card v-if="!loading">
          <div slot="header">
            <i class="fas fa-exclamation-triangle"></i> Warn settings
          </div>
          <h4 class="smalltitle">Warn settings</h4>

          <b-form-select
            id="mute_select"
            v-model="warn_settings.mode"
            value="Please select"
            class="mb-1"
          >
            <option
              v-for="t in creation_modes"
              :key="t.value"
              :value="t.value"
              :disabled="!is_vip && t.value === 4"
              >{{ t.name }}</option
            >
          </b-form-select>
          <b>Example:</b>
          {{
            warn_val.example
              .replace("muted", "warned")
              .replace(" for 20 minutes", "")
          }}
          <div v-if="warn_settings.mode === 4">
            <h6 class="mt-2">Custom message</h6>
            <p>
              Supports
              <a
                href="https://docs.carl.gg/#/tagstriggers?id=advanced-usage"
                >regular tagscript.</a
              >
              <br />
              <code>{reason}</code> - The reason supplied if there was one,
              'N/A' otherwise.
              <br />
              <code>{moderator}</code> - A userblock for the responsible
              moderator
              <br />
              <code>{offender}</code> - A userblock for the offender
              <br />
            </p>
            <b-input-group>
              <b-form-textarea
                :maxlength="2000"
                :rows="6"
                type="text"
                placeholder="Enter a warn message"
                class="col-12"
                v-model="warn_settings.message"
              ></b-form-textarea>
            </b-input-group>
            <EmbedBuilder ref="warn_embed"></EmbedBuilder>
          </div>
          <div slot="footer">
            <b-button variant="primary" v-on:click="put_settings('warn')"
              >Save</b-button
            >
          </div>
        </b-card>
        <b-card v-if="!loading">
          <div slot="header">
            <i class="fas fa-comment-slash"></i> Hardmute settings
          </div>
          <h4 class="smalltitle">Hardmute settings</h4>
          <p>
            Hardmute also removes all other roles in addition to applying the
            mute role.
          </p>
          <b-form-select
            id="hardmute_select"
            v-model="hardmute_settings.mode"
            value="Please select"
            class="mb-1"
          >
            <option
              v-for="t in creation_modes"
              :key="t.value"
              :value="t.value"
              :disabled="!is_vip && t.value === 4"
              >{{ t.name }}</option
            >
          </b-form-select>
          <b>Example:</b>
          {{ hardmute_val.example }}
          <div v-if="hardmute_settings.mode === 4">
            <h6 class="mt-2">Custom message</h6>
            <p>
              Supports
              <a
                href="https://docs.carl.gg/#/tagstriggers?id=advanced-usage"
                >regular tagscript.</a
              >
              <br />
              <code>{reason}</code> - The reason supplied if there was one,
              'N/A' otherwise.
              <br />
              <code>{moderator}</code> - A userblock for the responsible
              moderator
              <br />
              <code>{offender}</code> - A userblock for the offender
              <br />
              <code>{duration}</code> - A relative timestamp like '3 hours 24
              minutes' or an empty string if permanent
            </p>
            <b-input-group>
              <b-form-textarea
                :maxlength="2000"
                :rows="6"
                type="text"
                placeholder="Enter a hardmute message"
                class="col-12"
                v-model="hardmute_settings.message"
              ></b-form-textarea>
            </b-input-group>
            <EmbedBuilder ref="hardmute_embed"></EmbedBuilder>
          </div>
          <hr />
          <h6>Default hardmute duration</h6>
          <b-form-group>
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="2"
                autocomplete="2"
                v-model="hardmute_settings.hours"
                min="0"
                max="1440"
                maxlength="2"
                v-on:keydown="handleNonDecimalInput"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1 mb-2">Hours</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="2"
                autocomplete="2"
                v-model="hardmute_settings.minutes"
                min="0"
                max="1440"
                v-on:keydown="handleNonDecimalInput"
                maxlength="2"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Minutes</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <div slot="footer">
            <b-button variant="primary" v-on:click="put_settings('hardmute')"
              >Save</b-button
            >
          </div>
        </b-card>
        <b-card v-if="!loading">
          <div slot="header">
            <i class="fas fa-gavel mr-2"></i>
            <i class="fas fa-clock"></i> Tempban settings
          </div>
          <h4 class="smalltitle">Tempban settings</h4>

          <b-form-select
            id="tempban_select"
            v-model="tempban_settings.mode"
            value="Please select"
            class="mb-1"
          >
            <option
              v-for="t in creation_modes"
              :key="t.value"
              :value="t.value"
              :disabled="!is_vip && t.value === 4"
              >{{ t.name }}</option
            >
          </b-form-select>
          <b>Example:</b>
          {{
            tempban_val.example
              .replace("in", "from")
              .replace("muted", "temporarily banned")
          }}
          <div v-if="tempban_settings.mode === 4">
            <h6 class="mt-2">Custom message</h6>
            <p>
              Supports
              <a
                href="https://docs.carl.gg/#/tagstriggers?id=advanced-usage"
                >regular tagscript.</a
              >
              <br />
              <code>{reason}</code> - The reason supplied if there was one,
              'N/A' otherwise.
              <br />
              <code>{moderator}</code> - A userblock for the responsible
              moderator
              <br />
              <code>{offender}</code> - A userblock for the offender
              <br />
              <code>{duration}</code> - A relative timestamp like '3 hours 24
              minutes' or an empty string if permanent
            </p>
            <b-input-group>
              <b-form-textarea
                :maxlength="2000"
                :rows="6"
                type="text"
                placeholder="Enter a tempban message"
                class="col-12"
                v-model="tempban_settings.message"
              ></b-form-textarea>
            </b-input-group>
            <EmbedBuilder ref="tempban_embed"></EmbedBuilder>
          </div>
          <hr />
          <h6>Default tempban duration</h6>
          <b-form-group>
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="2"
                autocomplete="2"
                v-model="tempban_settings.hours"
                min="0"
                max="1440"
                maxlength="2"
                v-on:keydown="handleNonDecimalInput"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1 mb-2">Hours</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="2"
                autocomplete="2"
                v-model="tempban_settings.minutes"
                min="0"
                max="1440"
                maxlength="2"
                v-on:keydown="handleNonDecimalInput"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Minutes</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <hr />
            <h6>Default tempban message purge days</h6>
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="2"
                autocomplete="2"
                v-model="tempban_settings.days"
                min="0"
                max="7"
                maxlength="2"
                v-on:keydown="handleNonDecimalInput"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Days</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <div slot="footer">
            <b-button variant="primary" v-on:click="put_settings('tempban')"
              >Save</b-button
            >
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Callout } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import Multiselect from "vue-multiselect";
import EmbedBuilder from "@/views/oc/EmbedBuilder";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};

const reducer = (accumulator, currentValue, currentIndex) =>
  accumulator + (currentValue << currentIndex);

function finder(arr, id) {
  if (!id) {
    return { id: null, name: "No channel selected" };
  }
  var o = arr.find(obj => {
    return obj.id == id;
  });
  if (o && o.length !== 0) {
    return o;
  }
  return { id: null, name: "No channel selected" };
}

function listfinder(arr, id) {
  return arr.find(obj => {
    return obj.id === id;
  });
}

Vue.use(VueNotifications, toast_options);

export default {
  name: "Moderation",
  components: {
    cSwitch,
    Multiselect,
    EmbedBuilder
  },
  data: function() {
    return {
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      statuses: [true, true, true, true, true, true, true, true, true, true],
      loading: true,
      ban_settings: {
        mode: 0,
        message: "",
        days: 2
      },
      mute_settings: {
        mode: 2,
        message: "",
        hours: 0,
        minutes: 0
      },
      timeout_settings: {
        mode: 2,
        message: "",
        hours: 1,
        minutes: 0
      },
      warn_settings: {
        mode: 3,
        message: ""
      },
      kick_settings: {
        mode: 0,
        message: ""
      },
      tempban_settings: {
        mode: 0,
        message: "",
        days: 2,
        hours: 0,
        minutes: 0
      },
      hardmute_settings: {
        mode: 2,
        message: "",
        hours: 0,
        minutes: 0
      },
      channels: [],
      modlog_channel: null,
      report_channel: null,
      is_vip: false,
      creation_modes: [
        { name: "Do not dm offender", value: 0, example: "N/A" },
        {
          name: "Send server and action",
          value: 1,
          example: "You were muted in CBH for 20 minutes."
        },
        {
          name: "Send server, action and reason",
          value: 2,
          example: "You were muted in CBH for 20 minutes. Reason: 'DMing ads'"
        },
        {
          name: "Send server, action, name, reason and moderator",
          value: 3,
          example:
            "You were muted in CBH for 20 minutes. Reason: 'DMing ads'. Moderator: Carl#0001 (id: 106429844627169280)"
        },
        {
          name: "Send custom (premium)",
          value: 4,
          example: "Up to your imagination!"
        }
      ]
    };
  },
  notifications: {
    showSuccessMsg: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Setting applied"
    },
    showTimeoutErrorMessage: {
          type: VueNotifications.types.error,
          title: "Error",
          message: "Timeout duration should be between 1 min to 28 days"
    }
  },
  methods: {
    bit_to_array(bitmask) {
      for (let [i, e] of this.statuses.entries()) {
        this.statuses[i] = (bitmask & Math.pow(2, i)) !== 0;
      }
    },
    seconds_to_multi(seconds) {
      const hours = Math.floor(seconds / 3600);
      const rest = seconds % 3600;
      return [hours, Math.floor(rest / 60)];
    },
    handleKeyDown(event) {
    this.handleNonDecimalInput(event);
    const currentValue = parseInt(event.target.value + event.key);
    if (isNaN(currentValue) || currentValue > 1000) {
       event.preventDefault();
    }
    },
    put_settings(kind) {
      var d;
      var embed;
      switch (kind) {
        case "basic":
          d = {
            logging: this.logging_val,
            report_channel: this.report_channel ? this.report_channel.id : null,
            modlog_channel: this.modlog_channel ? this.modlog_channel.id : null
          };
          break;
        case "ban":
          d = this.ban_settings;
          embed = this.$refs.ban_embed;
          break;
        case "mute":
          d = this.mute_settings;
          embed = this.$refs.mute_embed;
          break;
        case "timeout":
          d = this.timeout_settings;
          embed = this.$refs.timeout_embed;
          break;
        case "tempban":
          d = this.tempban_settings;
          embed = this.$refs.tempban_embed;
          break;
        case "hardmute":
          d = this.hardmute_settings;
          embed = this.$refs.hardmute_embed;
          break;
        case "warn":
          d = this.warn_settings;
          embed = this.$refs.warn_embed;
          break;
        case "kick":
          d = this.kick_settings;
          embed = this.$refs.kick_embed;
          break;
        default:
          return showSuccessMsg({
            type: VueNotifications.types.error,
            title: "Fail",
            message: "Not sure how, but this just broke"
          });
      }
      d.kind = kind;
      d.embed =
        embed && Object.keys(embed.full_json).length > 1
          ? JSON.stringify(embed.full_json)
          : null;
      this.axios
        .put(
          "/api/v1/servers/" + this.$route.params.guild_id + "/moderation",
          d
        )
        .then(r => {
         if(r.data.error === "TIMEOUT_DURATION_ERROR") {
            this.showTimeoutErrorMessage()
          } else {
            this.showSuccessMsg()
          }
      })
    }
  },
  created: function() {
    this.$Progress.start();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/moderation")
      .then(r => {
        this.channels = r.data.channels;
        this.loading = false;
        if (r.data.modlogs.logging_bitmask != null) {
          this.bit_to_array(r.data.modlogs.logging_bitmask);
        }

        if (r.data.overrides.ban) {
          this.ban_settings.mode = r.data.overrides.ban.mode;
          this.ban_settings.message = r.data.overrides.ban.message;
          this.ban_settings.days = r.data.overrides.ban.default_time;
          if (r.data.overrides.ban.embed) {
            setTimeout(() => {
              this.$refs.ban_embed.apply_json(
                JSON.parse(r.data.overrides.ban.embed)
              );
            }, 0);
          }
        }

        if (r.data.overrides.kick) {
          this.kick_settings.mode = r.data.overrides.kick.mode;
          this.kick_settings.message = r.data.overrides.kick.message;
          this.kick_settings.days = r.data.overrides.kick.default_time;
          if (r.data.overrides.kick.embed) {
            setTimeout(() => {
              this.$refs.kick_embed.apply_json(
                JSON.parse(r.data.overrides.kick.embed)
              );
            }, 0);
          }
        }

        if (r.data.overrides.warn) {
          this.warn_settings.mode = r.data.overrides.warn.mode;
          this.warn_settings.message = r.data.overrides.warn.message;
          this.warn_settings.days = r.data.overrides.warn.default_time;
          if (r.data.overrides.warn.embed) {
            setTimeout(() => {
              this.$refs.warn_embed.apply_json(
                JSON.parse(r.data.overrides.warn.embed)
              );
            }, 0);
          }
        }

        if (r.data.overrides.mute) {
          var [hours, minutes] = this.seconds_to_multi(
            r.data.overrides.mute.default_duration
          );
          this.mute_settings.mode = r.data.overrides.mute.mode;
          this.mute_settings.message = r.data.overrides.mute.message;
          this.mute_settings.days = r.data.overrides.mute.default_time;
          this.mute_settings.hours = hours;
          this.mute_settings.minutes = minutes;
          if (r.data.overrides.mute.embed) {
            setTimeout(() => {
              this.$refs.mute_embed.apply_json(
                JSON.parse(r.data.overrides.mute.embed)
              );
            }, 0);
          }
        }

        if (r.data.overrides.timeout) {
          var [hours, minutes] = this.seconds_to_multi(
            r.data.overrides.timeout.default_duration
          );
          this.timeout_settings.mode = r.data.overrides.timeout.mode;
          this.timeout_settings.message = r.data.overrides.timeout.message;
          this.timeout_settings.days = r.data.overrides.timeout.default_time;
          this.timeout_settings.hours = hours;
          this.timeout_settings.minutes = minutes;
          if (r.data.overrides.timeout.embed) {
            setTimeout(() => {
              this.$refs.timeout_embed.apply_json(
                JSON.parse(r.data.overrides.timeout.embed)
              );
            }, 0);
          }
        }

        if (r.data.overrides.tempban) {
          var [hours, minutes] = this.seconds_to_multi(
            r.data.overrides.tempban.default_duration
          );
          this.tempban_settings.mode = r.data.overrides.tempban.mode;
          this.tempban_settings.message = r.data.overrides.tempban.message;
          this.tempban_settings.days = r.data.overrides.tempban.default_time;
          this.tempban_settings.hours = hours;
          this.tempban_settings.minutes = minutes;
          if (r.data.overrides.tempban.embed) {
            setTimeout(() => {
              this.$refs.tempban_embed.apply_json(
                JSON.parse(r.data.overrides.tempban.embed)
              );
            }, 0);
          }
        }

        if (r.data.overrides.hardmute) {
          var [hours, minutes] = this.seconds_to_multi(
            r.data.overrides.hardmute.default_duration
          );
          this.hardmute_settings.mode = r.data.overrides.hardmute.mode;
          this.hardmute_settings.message = r.data.overrides.hardmute.message;
          this.hardmute_settings.days = r.data.overrides.hardmute.default_time;
          this.hardmute_settings.hours = hours;
          this.hardmute_settings.minutes = minutes;
          if (r.data.overrides.hardmute.embed) {
            setTimeout(() => {
              this.$refs.hardmute_embed.apply_json(
                JSON.parse(r.data.overrides.hardmute.embed)
              );
            }, 0);
          }
        }

        this.$Progress.finish();
        this.is_vip = r.data.is_vip;
        let mc = r.data.modlogs.channel_id || null;
        this.modlog_channel = this.channels.find(c => c.id === mc);
        let rc = r.data.modlogs.report_channel || null;
        this.report_channel = this.channels.find(c => c.id === rc);
      });
  },
  computed: {
    logging_val: function() {
      return this.statuses.reduce(reducer);
    },
    ban_val() {
      return this.creation_modes.find(x => x.value == this.ban_settings.mode);
    },
    mute_val() {
      return this.creation_modes.find(x => x.value == this.mute_settings.mode);
    },
    timeout_val() {
      return this.creation_modes.find(
        x => x.value == this.timeout_settings.mode
      );
    },
    warn_val() {
      return this.creation_modes.find(x => x.value == this.warn_settings.mode);
    },
    kick_val() {
      return this.creation_modes.find(x => x.value == this.kick_settings.mode);
    },
    tempban_val() {
      return this.creation_modes.find(
        x => x.value == this.tempban_settings.mode
      );
    },
    hardmute_val() {
      return this.creation_modes.find(
        x => x.value == this.hardmute_settings.mode
      );
    },
    groupedChannels() {
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized"
          },
          channels: []
        }
      ];
      this.channels.sort((a, b) => a.position - b.position);
      let currentGroup = groups[0];
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          currentGroup = { category: chn, channels: [] };
          groups.push(currentGroup);
        }
      }
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          continue;
        }
        currentGroup = groups.find(g => {
          return g.category.id == chn.parent_id;
        });
        if (!currentGroup) {
          continue;
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    }
  }
};
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
  width: 100% !important;
}

.confirm {
  align-items: center;
  position: fixed;
  bottom: 20px;
}
</style>
<style lang="scss" scoped>
$grey: rgb(34, 37, 39);

.popup {
  background-color: $grey;
  border-radius: 0.5rem;
  display: flex;
  //margin: 0.5rem;
  position: fixed;
  bottom: 20px;
  z-index: 2000;
  //padding: 0.65rem;
  align-items: center;
  box-shadow: 0.05rem 0.1rem 0.2rem 0.2rem rgba(0, 0, 0, 0.08);
  transition: bottom 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
</style>
